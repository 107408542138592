import React from "react";

const CTA = () => {
  return (
    <div className="cta">
      <a
        href="https://docs.google.com/document/d/1dJhA8XLUDLEXQdzbQTbYmdzVCzouCdLf63taguBDjnE/edit?usp=drive_link"
        target={"_blank"}
        className="btn"
        rel="noreferrer"
      >
        Resume
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's talk
      </a>
    </div>
  );
};

export default CTA;
