import React from "react";
import zee from "../../assets/zee5.png";
import koo from "../../assets/koo.png";
import dailyhunt from "../../assets/daily.png";
import fantasy from "../../assets/fantasy.png";
import dermstore from "../../assets/dermstore.png";

import "./portfolio.css";

const Portfolio = () => {
  const soloProjects = [
    {
      id: 0,
      title: "DearmStore || Ecommerce Website",
      img: dermstore,
      des: [
        {
          li: "Multiuser Signup & Login",
        },
        {
          li: "Sort Products by Price | Filter Products by Category",
        },
        {
          li: "Cart Page with inc,dec,add,remove All Crud operations",
        },
        {
          li: "Buy product | Payment Page",
        },
      ],
      team: "A Team project, executed in 8 days",
      teckStack:
        "HTML || CSS || React || Redux || Node.js || MongoDB || Express.js || Chakra ui",
      link: "https://dermstore-clone-01.vercel.app/products",
      github: "https://github.com/ayush-kr05/DermStore_Clone",
    },
    {
      id: 1,
      title: "ESPN-FANTASY || Fantasy League Website",
      img: fantasy,
      des: [
        {
          li: "Sign Up | Login | All Page",
        },
        {
          li: "Sort Player by Asc & Dsc",
        },
        {
          li: "Join Contest & Stored data on server",
        },
        {
          li: "Create Player list",
        },
      ],
      team: "A solo project, executed in 5 days",
      teckStack: "HTML || CSS || React || Redux || Chakra ui",
      link: "https://espn-fantacy.netlify.app/",
      github: "https://github.com/rupeshkarale/ESPN-Fantacy-Website",
    },
    {
      id: 2,
      title: "Zee5-Clone || OTT App",
      img: zee,
      des: [
        {
          li: "Sign Up | Login | All Page - with Filter & SortFunctionality",
        },
        {
          li: "Search Functionality for Movies and TVShows",
        },
        {
          li: "Single Video Page (dynamic) along with Add To Watch List Feature",
        },
        {
          li: "Buy Plan | Payment Page along with all functionality",
        },
      ],
      team: "A collaborative project built by a team of 5, executed in 6 days",
      teckStack: "HTML || CSS || JavaScript || Json",
      link: "https://zee5clone22.netlify.app/index.html",
      github: "https://github.com/Vaibhav3534/Zee5-Clone",
    },
    {
      id: 3,
      title: "Dailyhunt || News Website",
      des: [
        {
          li: "Various Categories of News.",
        },
        {
          li: "Save News Feature",
        },
        {
          li: "All Pages",
        },
        {
          li: "UnSave News Feature",
        },
        {
          li: "Search & Find News",
        },
      ],
      img: dailyhunt,
      team: "A solo project, executed in 2 days",
      teckStack: "HTML | CSS | JavaScript | React | Redux",
      link: "https://dailyhunt-clone.netlify.app/",
      github: "https://github.com/rupeshkarale/Dailyhunt-Clone",
    },
    {
      id: 4,
      title: "KOO-Clone || Social Media Website",
      des: [
        {
          li: "Sign Up | Login | All Page",
        },
        {
          li: "Follow Button For Follow",
        },
        {
          li: "Like Button For Like Post & Like Count Changes",
        },
        {
          li: "User-name Comes (Dynamic) From Local Storage ",
        },
      ],
      img: koo,
      team: "A collaborative project built by a team of 4, executed in 6 days",
      teckStack: "HTML | CSS | JavaScript | LocalStorage",
      link: "https://voluble-nasturtium-deb677.netlify.app/feed.html",
      github: "https://github.com/rupeshkarale/KooEnglishClone",
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <h3 className="portfolio__title">{pro.title}</h3>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <br />
            <ul className="des">
              {pro.des.map((elem) => (
                <li style={{ listStyleType: "square" }}>{elem.li}</li>
              ))}
            </ul>
            <br />
            <p style={{ color: "silver" }}>{pro.team}</p>
            <p style={{ color: "silver" }}>{pro.teckStack}</p>
            <div className="portfolio__item-cta">
              <a
                href={pro.github}
                target="_blank"
                rel="noreferrer"
                className="btn"
              >
                GitHub
              </a>
              <a
                href={pro.link}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
